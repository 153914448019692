import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Container } from "reactstrap";
import PrivacybeleidModal from "./Modals/PrivacyPolicyModal";
import PrivacyPolicyModal from "./Modals/PrivacyPolicyModal";
import DataProtectionModal from "./Modals/DataProtectionModal";

const Footer = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const [dataProtectionModalIsOpen, setDataProtectionModalIsOpen] =
        useState(false);

    const dataProtectionToggleModal = () => {
        setDataProtectionModalIsOpen(!dataProtectionModalIsOpen);
    };
    return (
        <Container className="text-center my-5">
            <p className="fs--14 text-site-gray">
                Copyright 2023 Mijn-Offertes.be - Alle rechten voorbehouden.
            </p>

            <div className="footer d-flex justify-content-center align-items-center ">
                <p>
                    {" "}
                    <a
                        href="https://www.bobex.be/nl-be/algemene-voorwaarden/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-site-link  "
                    >
                        Algemene voorwaarden
                    </a>{" "}
                    |{" "}
                    <a
                        // href="https://www.bobex.be/nl-be/bobex-gegevensbescherming-gdpr/"
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className="text-site-link "
                        onClick={dataProtectionToggleModal}
                    >
                        Gegevensbescherming
                    </a>{" "}
                    |{" "}
                    <a
                        // href="https://www.bobex.be/nl-be/privacybeleid/"
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={toggleModal}
                        className="text-site-link "
                    >
                        Privacybeleid
                    </a>{" "}
                </p>
            </div>
            <PrivacyPolicyModal
                modalIsOpen={modalIsOpen}
                toggleModal={toggleModal}
            />
            <DataProtectionModal
                dataProtectionModalIsOpen={dataProtectionModalIsOpen}
                dataProtectionToggleModal={dataProtectionToggleModal}
            />
        </Container>
    );
};

export default Footer;
