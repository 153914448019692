import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function DataProtectionModal({
    dataProtectionModalIsOpen,
    dataProtectionToggleModal,
}) {
    return (
        <Modal
            isOpen={dataProtectionModalIsOpen}
            toggle={dataProtectionToggleModal}
            centered
            size="lg"
        >
            <ModalHeader toggle={dataProtectionToggleModal}>
                <h2>Bobex gegevensbescherming (GDPR)</h2>
            </ModalHeader>
            <ModalBody className="modal-scrollable">
                <div className="scrollable-content">
                    <h4>Altijd en overal toegang tot jouw gegevens</h4>
                    <p>
                        Als Bobex-gebruiker heb je een persoonlijke login.
                        Daarmee kan je jouw gegevens bekijken, aanpassen en
                        beheren. Heb je je Bobex-account niet meer nodig? Dan
                        kan je je account ook volledig verwijderen.
                    </p>
                    <br />
                    <h4>Volledig zelf onder controle</h4>
                    <p>
                        In elke stap van onze dienstverlening heb je alles
                        volledig zelf onder controle. Je doet zelf een aanvraag.
                        Wij bellen je vervolgens even op en overlopen met jou de
                        aanvraag. Je beslist op dat moment zelf of we jouw
                        gegevens doorsturen naar onze erkende partners. Daarna
                        kan je onze leveranciers evalueren via jouw Bobex-login.
                    </p>
                    <br />
                    <h4>Data privacy voor leveranciers</h4>
                    <p>
                        Ben je een Bobex-leverancier? Dan heb je toegang tot een
                        beveiligde zone op onze website. In dit extranet kan je
                        jouw profiel bekijken en alle persoonlijke gegevens
                        aanpassen. Je kan je profiel vergrendelen en vragen om
                        het te verwijderen.
                    </p>
                    <br />
                    <h4>Melding bij onregelmatigheden</h4>
                    <p>
                        De Bobex-infrastructuur en alle gegevens van onze
                        databanken worden gehost bij een externe firma die
                        gespecialiseerd is in de bescherming van gegevens. Deze
                        firma gebruikt de meest recente technologieën om onze
                        gegevens op een veilige manier te beschermen. Indien er
                        ooit een beveiligingsprobleem zou opduiken, zullen wij
                        je hiervan onmiddellijk op de hoogte brengen.
                    </p>
                    <br />
                    <h4>GDPR binnen Bobex</h4>
                    <p>
                        Wij volgen de Europese Privacyregels (GDPR) en hebben
                        een Data Protection Manager aangesteld die de
                        implementatie van alle GDPR regels binnen ons bedrijf
                        nauwkeurig opvolgt. De Data Protection Manager is het
                        aanspreekpunt voor alle vragen of opmerkingen omtrent
                        data en privacy. Je kan onze Data Protection Manager
                        bereiken door te mailen naar privacy@bobex.be.
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="bg-site-dark-secondary border-0"
                    onClick={dataProtectionToggleModal}
                >
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default DataProtectionModal;
