import Footer from "../components/Footer";

const Main = (props) => {
    return (
        <>
            <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
                {/* <Header /> */}
                <div style={{ flexGrow: 2 }}>{props.children}</div>
                <Footer paddingBottom="pb-3" />
            </div>
            {/* {props.children}
            <Footer /> */}
        </>
    );
};

export default Main;
